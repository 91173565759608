const CDN_BASE_URL = 'https://d27snhiba7p64h.cloudfront.net';

const buildCdnUrl = (imagePath, { width, format, quality }) => {
  const params = new URLSearchParams();

  if (width) params.append('width', width);
  if (format) params.append('format', format);
  if (quality) params.append('quality', quality);

  return `${CDN_BASE_URL}/${imagePath}?${params.toString()}`;
};

export default buildCdnUrl;
